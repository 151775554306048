import request from '@/utils/request'
// 查询设备类型列表
export function listDeviceType (query) {
  return request({
    url: '/gx/gx-device-type/list',
    method: 'get',
    params: query
  })
}
// 查询设备类型分页
export function pageDeviceType (query) {
  return request({
    url: '/gx/gx-device-type/page',
    method: 'get',
    params: query
  })
}

// 查询设备类型详细
export function getDeviceType (data) {
  return request({
    url: '/gx/gx-device-type/detail',
    method: 'get',
    params: data
  })
}
// 新增设备类型
export function addDeviceType (data) {
  return request({
    url: '/gx/gx-device-type/add',
    method: 'post',
    data: data
  })
}
// 修改设备类型
export function updateDeviceType (data) {
  return request({
    url: '/gx/gx-device-type/edit',
    method: 'post',
    data: data
  })
}

// 删除设备类型
export function delDeviceType (data) {
  return request({
    url: '/gx/gx-device-type/delete',
    method: 'post',
    data: data
  })
}
